import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NotesService } from '../../../library/services/notes.service';

@Injectable()
export class QuickviewService {
  constructor(
    private http: Http,
    private notesService: NotesService
  ) {}

  // Get from the API
  getNotes() {
    return this.notesService.getUserNotes('71n9ml1KL3YEoTMDEiVIxrAzSTc2').subscribe( notes => {
      return notes;
    });
    // return this.http.get('assets/data/notes.json').map(res => res.json());
  }

  getUsers() {
    return this.http.get('assets/data/users.json').map(res => res.json());
  }

  getChatMessages() {
    return this.http.get('assets/data/messages.json').map(res => res.json());
  }
}
