import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../../../library/services/auth.service';

@Component({
  selector: 'pg-menu-items-new',
  templateUrl: './menu-new.component.html',
  styleUrls: ['./menu-new.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'close',
        style({
          height: '0',
          overflow: 'hidden',
          padding: '0px'
        })
      ),
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden',
          padding: '18px 0 10px 0'
        })
      ),
      transition('close => open', [animate('140ms ease-in')]),
      transition('open => close', [animate('140ms ease-out')])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuNewComponent implements OnInit {

  @Input()
  set Items(value) {
    this.menuItems = value;
  }
  userProfile: any;
  menuItems = [];
  currentItem = null;

  constructor(private authService: AuthService) {
    this.authService.userProfile.subscribe(userProfile => {
      // console.log(userProfile);
      this.userProfile = userProfile;
    });
  }

  ngOnInit() {
  }

  getUserPermissions(roles: any[], permissions: any[], label: string) {
    let canByRole = roles.includes(this.userProfile.role);
    let canByPermission = false;
    const userPermissions = this.userProfile.permissions || [];
    if (userPermissions.length > 0) {
      for (const permission of userPermissions) {
        if (permissions.includes(permission)) {
          canByPermission = true;
        }
      }
    }
    return (canByRole || canByPermission);
  }

  toggleNavigationSub(event, item) {
    event.preventDefault();
    if (this.currentItem && this.currentItem !== item) {
      this.currentItem['toggle'] = 'close';
    }
    this.currentItem = item;
    item.toggle = item.toggle === 'close' ? 'open' : 'close';
  }

}
