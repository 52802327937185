import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';
import { MessageService } from '../../@pages/components/message/message.service';
import { Router } from '@angular/router';
import { GregorgpsService } from './gregorgps.service';
import { AngularFirestore } from '@angular/fire/firestore';

const USER_INFO = 'USER_INFO';
const USER_PROFILE = 'USER_PROFILE';

export enum EPermissions {
  canValuations = 'canValuations',
  canNewContract = 'canNewContract',
  canAuthorizeDiscounts = 'canAuthorizeDiscounts',
  canUnblockContract = 'canUnblockContract',
  canBlockContract = 'canBlockContract',
  canUnblockCar = 'canUnblockCar',
  canBlockCar = 'canBlockCar',
  canCatalogs = 'canCatalogs',
  candSendSMS = 'candSendSMS',
  canCustomSMS = 'canCustomSMS',
  canSellCars = 'canSellCars',
  canChangeRates = 'canChangeRates',
  canAdminUsers = 'canAdminUsers',
  canGregorGPS = 'canGregorGPS'
}

export enum ERoles {
  accounting = 'accounting',
  accounts = 'accounts',
  admin = 'admin',
  manager = 'manager',
  service = 'service',
  valuations = 'valuations'
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {


  user: Observable<any>;
  userProfile: Observable<any>;

  authState$: BehaviorSubject<any> = new BehaviorSubject(null);
  profileState$: BehaviorSubject<any> = new BehaviorSubject(null);

  unsubscribeOnSignout$: BehaviorSubject<boolean> = new BehaviorSubject(null);


  constructor(private afs: AngularFirestore, private auth: AngularFireAuth, private gregorGPSService: GregorgpsService, public notification: MessageService, private router: Router) {

    this.loadUser();
    this.unsubscribeOnSignout$.next(false);
    this.unsubscribeOnSignout$.complete();
    this.user = this.authState$.asObservable().pipe(
      filter(response => response)
    );
    this.userProfile = this.profileState$.asObservable().pipe(
      filter(response => response)
    );
  }

  async loadUser() {
    const data = await JSON.parse(localStorage.getItem(USER_INFO));
    const profile = await JSON.parse(localStorage.getItem(USER_PROFILE));
    if(!!profile) {
      this.profileState$.next(profile);
    } else {
      this.profileState$.next({role: 'guest', email:null });
    }
    if(!!data) {
      this.authState$.next(data);
    } else {
      this.authState$.next({ email: null, role: null });
    }
  }

  async loadUserProfile(user:any) {
    const userProfile = this.afs.collection('users').doc(user.uid);
    userProfile.valueChanges().pipe(
      takeUntil(this.unsubscribeOnSignout$),
      tap(user => {
        this.profileState$.next(user);
      })
    ).subscribe();
  }

  login(email: string, password: string) {
    this.auth.auth.signInWithEmailAndPassword(email, password).then((response) => {
      if (!!response && response.user) {
        this.validateUser(response.user);
      } else {
        this.notificationMessage('danger', 'Hay un problema.');
      }
    })
      .catch(err => {
        this.notificationMessage('danger', err.message);
      })
  }

  logout() {
    return this.auth.auth.signOut().then(() => {
      this.unsubscribeOnSignout$.next(true);
      this.unsubscribeOnSignout$.complete();
      return localStorage.clear();

    })
  }

  notificationMessage(type: string, content: string) {
    this.notification.create(type, content, {
      Position: "top-right",
      Style: 'flip',
      Duration: 3000,
      PauseOnHover: true
    });
  }

  validateUser(user: any) {
    const userProfile = this.afs.collection('users').doc(user.uid);
    userProfile.valueChanges().pipe(
      takeUntil(this.unsubscribeOnSignout$),
    tap((userProfile: any) => {
      const disabled = userProfile.disabled || false;
      if (disabled) {
        this.notificationMessage('danger', 'Su cuenta de usuario está deshabilitada. Si está deshabilitado por error, por favor contáctanos.');
        this.logout().then(() => {
          this.router.navigateByUrl('session/login');
        })
          .catch(err => console.log(err));
      } else {
        this.notificationMessage('success', 'Bienvenido');
        localStorage.setItem(USER_INFO, JSON.stringify(user));
        localStorage.setItem(USER_PROFILE, JSON.stringify(userProfile));
        this.authState$.next(user);
        this.profileState$.next(userProfile);
        this.router.navigateByUrl('');
      }
    })
    ).subscribe();
  }

  // hasPermission(permissions: any[]): boolean {
  //   const userProfile: any = this.userProfile;
  //   // console.log(userProfile);
  //   if(!!this.userProfile) {
  //     if (this.userProfile && userProfile.permissions) {
  //       for (const permission of userProfile.permissions) {
  //         if(permissions.includes(permission)) {
  //           // console.log("hasRoles is true");
  //           return true;
  //         }
  //       }
  //       return false;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // async hasRole(role: any[]) {
  //   // console.log('role length: ', role.length)
  //   if(role.length === 0) return false;

  //   return this.userProfile.pipe(
  //     take(1),
  //     map(userProfile => {
  //       // console.log(userProfile);
  //       // console.log('role includes: ', role.includes(userProfile.role))
  //       return role.includes(userProfile.role)
  //     })
  //   ).subscribe( (userHasRole:boolean) => {
  //     // console.log('userHasRole: ', userHasRole);
  //     return userHasRole;
  //   }, err => {
  //     return false;
  //   });
    
  // }

}
