import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import { EPermissions, ERoles } from '../../../library/services/auth.service';

@Component({
  selector: 'condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CondensedComponent extends RootLayout implements OnInit {

  currentYear: number = new Date().getFullYear();
  ePerm = EPermissions;
  eRoles = ERoles;

  menuLinks = [
    {
      label: 'Principal',
      details: '12 New Updates',
      routerLink: '/dashboard',
      iconType: 'pg',
      iconName: 'home',
      roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
      permissions: []
    },
    // {
    //   label: 'Resultados',
    //   iconType: 'letter',
    //   iconName: 'R',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   roles: [this.eRoles.admin],
    //   permissions: [],
    //   submenu: [
    //     {
    //       label: 'Al día',
    //       routerLink: 'ui/color',
    //       iconType: 'pg',
    //       iconName: 'calendar',
    //       roles: [this.eRoles.admin],
    //       permissions: []
    //     },
    //     {
    //       label: 'Conglomerado',
    //       routerLink: 'ui/typography',
    //       iconType: 'pg',
    //       iconName: 'chart',
    //       roles: [this.eRoles.admin],
    //       permissions: []
    //     },
    //     {
    //       label: 'Costos',
    //       routerLink: 'ui/icons',
    //       iconType: 'pg',
    //       iconName: 'eye',
    //       roles: [this.eRoles.admin],
    //       permissions: []
    //     }]
    // },
    {
      label: 'Movimientos',
      iconType: 'letter',
      iconName: 'M',
      toggle: 'close',
      mToggle: 'close',
      roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
      permissions: [],
      submenu: [
        {
          label: 'Ingreso',
          routerLink: '/transactions/income',
          iconType: 'letter',
          iconName: 'I',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
          permissions: []
        },
        {
          label: 'Egreso',
          routerLink: '/transactions/expenses',
          iconType: 'letter',
          iconName: 'E',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
          permissions: []
        }]
    },
    {
      label: 'Colocación',
      iconType: 'letter',
      iconName: 'C',
      toggle: 'close',
      mToggle: 'close',
      roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
      permissions: [],
      submenu: [
        {
          label: 'Activa',
          routerLink: '/contracts/active',
          iconType: 'letter',
          iconName: 'Ac',
          roles: [this.eRoles.admin, this.eRoles.accounting, this.eRoles.manager],
          permissions: []
        },
        {
          label: 'Clientes',
          routerLink: '/customers',
          iconType: 'letter',
          iconName: 'Cl',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.accounting],
          permissions: []
        },
        {
          label: 'Todos los clientes',
          routerLink: '/allcustomers',
          iconType: 'letter',
          iconName: 'TC',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.accounting],
          permissions: []
        },
        {
          label: 'Contratos',
          routerLink: '/contracts',
          iconType: 'letter',
          iconName: 'Co',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
          permissions: [this.ePerm.canNewContract]
        },
        {
          label: 'Finiquitos',
          routerLink: '/contracts/historic',
          iconType: 'letter',
          iconName: 'Co',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.service, this.eRoles.valuations, this.eRoles.accounting],
          permissions: [this.ePerm.canNewContract]
        },
        {
          label: 'Nuevo',
          routerLink: '/contracts/new',
          iconType: 'pg',
          iconName: 'star',
          roles: [this.eRoles.admin, this.eRoles.manager],
          permissions: [this.ePerm.canNewContract]
        },
        {
          label: 'Valuaciones',
          routerLink: '/valuations',
          iconType: 'letter',
          iconName: 'V',
          roles: [this.eRoles.admin, this.eRoles.valuations, this.eRoles.manager, this.eRoles.service],
          permissions: [this.ePerm.canValuations]
        }
      ]
    },
    {
      label: 'Corte diario caja',
      routerLink: '/admin/dailycash',
      iconType: 'letter',
      iconName: 'D',
      roles: [this.eRoles.admin, this.eRoles.manager],
      permissions: []
    },
    {
      label: 'gregorGPS',
      routerLink: '/gps',
      iconType: 'pg',
      iconName: 'map',
      roles: [this.eRoles.admin, this.eRoles.manager],
      permissions: [this.ePerm.canGregorGPS]
    },
    {
      label: 'Autos a la venta',
      routerLink: '/admin/vehicles',
      iconType: 'letter',
      iconName: 'Av',
      roles: [this.eRoles.admin, this.eRoles.manager],
      permissions: [this.ePerm.canSellCars]
    },
    {
      label: 'Cobranza',
      routerLink: '/admin/collect',
      iconType: 'letter',
      iconName: 'C',
      roles: [this.eRoles.valuations, this.eRoles.service],
      permissions: []
    },
    {
      label: 'Auditoría',
      routerLink: '/audit',
      iconType: 'letter',
      iconName: 'A',
      roles: [this.eRoles.service],
      permissions: []
    },
    {
      label: 'Administrar',
      iconType: 'letter',
      iconName: 'A',
      toggle: 'close',
      mToggle: 'close',
      roles: [this.eRoles.admin, this.eRoles.accounting, this.eRoles.manager],
      permissions: [this.ePerm.canUnblockContract, this.ePerm.canBlockContract, this.ePerm.canAuthorizeDiscounts ],
      submenu: [
        {
          label: 'Auditoría',
          routerLink: '/audit',
          iconType: 'letter',
          iconName: 'A',
          roles: [this.eRoles.admin, this.eRoles.manager, this.eRoles.accounting],
          permissions: []
        },
        {
          label: 'Inversionistas',
          routerLink: '/admin/investors',
          iconType: 'letter',
          iconName: 'I',
          roles: [this.eRoles.admin],
          permissions: []
        },
        {
          label: 'Cobranza',
          routerLink: '/admin/collect',
          iconType: 'letter',
          iconName: 'C',
          roles: [this.eRoles.admin, this.eRoles.manager],
          permissions: []
        },
        {
          label: 'Metas de Sucursales',
          routerLink: '/admin/goals',
          iconType: 'letter',
          iconName: 'A',
          roles: [this.eRoles.admin],
          permissions: []
        },
        {
          label: 'Prospectos',
          routerLink: '/admin/prospects',
          iconType: 'letter',
          iconName: 'P',
          roles: [this.eRoles.admin, this.eRoles.manager],
          permissions: []
        },
        {
          label: 'Políticas',
          routerLink: '/admin/policies',
          iconType: 'letter',
          iconName: 'S',
          roles: [this.eRoles.admin],
          permissions: []
        },
        {
          label: 'Corte Mensual',
          routerLink: '/admin/periods',
          iconType: 'letter',
          iconName: 'M',
          roles: [this.eRoles.admin, this.eRoles.manager],
          permissions: []
        },
        {
          label: 'Números de contratos',
          routerLink: '/admin/counters',
          iconType: 'letter',
          iconName: 'No',
          roles: [this.eRoles.admin],
          permissions: []
        },
        {
          label: 'Bloqueos',
          routerLink: '/admin/lock',
          iconType: 'letter',
          iconName: 'B',
          roles: [this.eRoles.admin, this.eRoles.manager],
          permissions: [this.ePerm.canUnblockContract, this.ePerm.canBlockContract]
        },
        {
          label: 'Descuentos',
          routerLink: '/admin/discounts',
          iconType: 'letter',
          iconName: 'D',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canAuthorizeDiscounts]
        },
        {
          label: 'Límites de descuentos',
          routerLink: '/admin/userdiscountlimits',
          iconType: 'letter',
          iconName: 'L',
          roles: [this.eRoles.admin],
          permissions: []
        },
      ]
    },
    {
      label: 'Catálogos',
      iconType: 'letter',
      iconName: 'Ca',
      toggle: 'close',
      mToggle: 'close',
      roles: [this.eRoles.admin],
      permissions: [this.ePerm.canCatalogs],
      submenu: [
        {
          label: 'Usuarios',
          routerLink: '/catalogs/users',
          iconType: 'letter',
          iconName: 'Us',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Clientes',
          routerLink: '/catalogs/customers',
          iconType: 'letter',
          iconName: 'Cl',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Bancos',
          routerLink: '/catalogs/banks',
          iconType: 'letter',
          iconName: 'Ba',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Empresas',
          routerLink: '/catalogs/companies',
          iconType: 'letter',
          iconName: 'Em',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Sucursales',
          routerLink: '/catalogs/offices',
          iconType: 'letter',
          iconName: 'Su',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        }, {
          label: 'Formas de Pago',
          routerLink: '/catalogs/paymentMethods',
          iconType: 'letter',
          iconName: 'Fp',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Conceptos de Ingresos y Egresos',
          routerLink: '/catalogs/concepts',
          iconType: 'letter',
          iconName: 'Co',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Mensajes del sistema',
          routerLink: '/catalogs/jumbotron',
          iconType: 'letter',
          iconName: 'G4',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        },
        {
          label: 'Tipos de vehículos',
          routerLink: '/catalogs/vehicleTypes',
          iconType: 'letter',
          iconName: 'TV',
          roles: [this.eRoles.admin],
          permissions: [this.ePerm.canCatalogs]
        }
      ]
    }
  ];

  ngOnInit() { }
}
