import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { MessageService } from '../../@pages/components/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private _notification: MessageService,
    private angularFirestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging: any) => {
        // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging._next = (payload: any) => {
          // console.log(payload);
          this._notification.create(
            payload.data.type,
            payload.notification.body,
            {
              Position: "top-right",
              Style: "flip",
              Duration: 0
            }
          );
        };
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFirestore.doc('fcmTokens/webTokens').set(data, { merge: true });
      })
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   */
  removeToken(userId) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = null
        this.angularFirestore.doc('fcmTokens/webTokens').set(data, { merge: true });
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
       // this.removeToken(userId);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}