import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../library/services/auth.service';

@Component({
  selector: 'pg-menu-items',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'close',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden'
        })
      ),
      transition('close => open', animate('140ms ease-in')),
      transition('open => close', animate('140ms ease-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit {

  userProfile: any;
  menuItems = [];
  currentItem = null;
  isPerfectScrollbarDisabled = true;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(private authService: AuthService) {
    this.authService.userProfile.subscribe(userProfile => {
      // console.log(userProfile);
      this.userProfile = userProfile;
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.togglePerfectScrollbar();
    });
  }

  getUserPermissions(roles: any[], permissions: any[], label: string) {
    let canByRole = roles.includes(this.userProfile.role);
    let canByPermission = false;
    const userPermissions = this.userProfile.permissions || [];
    if (userPermissions.length > 0) {
      for (const permission of userPermissions) {
        if (permissions.includes(permission)) {
          canByPermission = true;
        }
      }
    }
    return (canByRole || canByPermission);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.togglePerfectScrollbar();
  }

  togglePerfectScrollbar() {
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025;    
  }

  @Input()
  set Items(value) {
    this.menuItems = value;
  }

  toggleNavigationSub(event, item) {
    event.preventDefault();
    if (this.currentItem && this.currentItem !== item) {
      this.currentItem['toggle'] = 'close';
    }
    this.currentItem = item;
    item.toggle = item.toggle === 'close' ? 'open' : 'close';
  }
}
