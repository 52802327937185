import { Routes } from '@angular/router';
import { CondensedComponent, BlankComponent } from './@pages/layouts';
import { UserProfileGuard } from './library/guards/user-profile.guard';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { map } from 'rxjs/operators';

const redirectUnauthorizedUsersTo = () => redirectUnauthorizedTo(['notauthorized']);

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'audit',
    component: CondensedComponent,
    data: {
      breadcrumb: 'Auditoría'
    },
    children: [
      {
        path: '',
        loadChildren: './audit/audit.module#AuditModule'
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    data: {
      breadcrumb: 'Administración'
    },
    children: [
      {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canActivate: [ UserProfileGuard ],
        data: {
          role: ['admin'],
          permissions: ['canEdit','canAdmin']
        }
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    data: {
      breadcrumb: 'Contratos'
    },
    children: [
      {
        path: 'contracts',
        loadChildren: './contracts/contracts.module#ContractsModule',
        canActivate: [ UserProfileGuard ],
        data: {
          role: ['admin','manager'],
          permissions: []
        }
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [
      {
        path: 'catalogs',
        canActivate: [ UserProfileGuard ],
        loadChildren: './catalogs/catalogs.module#CatalogsModule',
        data: {
          role: ['admin'],
          permissions: ['canEdit','canAdmin']
        }
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [
      {
        path: 'transactions',
        canActivate: [ UserProfileGuard ],
        loadChildren: './transactions/transactions.module#TransactionsModule',
        data: {
          role: ['admin'],
          permissions: ['canEdit','canAdmin']
        }
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [
      {
        path: 'valuations',
        canActivate: [ UserProfileGuard ],
        loadChildren: './valuations/valuations.module#ValuationsModule',
        data: {
          role: ['admin'],
          permissions: ['canEdit','canAdmin','canValuations']
        }
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [
      {
        path: 'gps',
        loadChildren: './gps/gps.module#GpsModule'
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    data: {
      breadcrumb: 'Clientes'
    },
    children: [
      {
        path: 'customers',
        loadChildren: './accounts/accounts.module#AccountsModule'
      },
      {
        path: 'allcustomers',
        loadChildren: './accounts-all/accounts.module#AllAccountsModule'
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    data: {
      breadcrumb: 'Principal'
    },
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'session',
        loadChildren: './session/session.module#SessionModule'
      }
    ]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      }
    ]
  },
  {
    path:'notauthorized',
    component: BlankComponent
  }
];
