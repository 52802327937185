import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private afs: AngularFirestore) { }

  getUserNotes(uid: string) {
    const notes = this.afs.collection('notes', ref => ref.where('uid','==', uid).orderBy('date','desc'));
    return notes.snapshotChanges();
  }

  addUserNote(note: any) {
    const newNote = this.afs.collection('notes').doc(note.id);
    return newNote.set({...note});
  }

  updateUserNote(noteId: string, updatedNote: any) {
    const note = this.afs.collection('notes').doc(noteId);
    return note.update(updatedNote);
  }

  deleteNote(noteId: string) {
    const note = this.afs.collection('notes').doc(noteId);
    return note.delete();
  }
}
