import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, take, shareReplay, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GregorgpsService {

  apiUrl = 'gps.gregor.city/api/';
  wsProtocol = 'wss://';
  httpProtocol = 'https://';
  httpOptions: any = {};
  gpsWebSocket: WebSocketSubject<any> = webSocket(`${this.wsProtocol}${this.apiUrl}socket`);

  constructor(public http: HttpClient) {
  }

  session(email: string, password: string) {

    var url = this.httpProtocol + this.apiUrl + "session";
    const body = new HttpParams()
      .set('email', email)
      .set('password', password);
    var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const request = this.http.post(url, body.toString(), { headers: headers, withCredentials: true, observe: 'response' });
    return request;
  }

  webSocketConnect() {
    return this.gpsWebSocket;
  }

  getDevices() {
    
  }
}
