// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // apiKey: "AIzaSyB-exERb5KQfk1XyO_EUhLu_qDOSgLKjHg",
    // authDomain: "apoyo-eficiente.firebaseapp.com",
    // databaseURL: "https://apoyo-eficiente.firebaseio.com",
    // projectId: "apoyo-eficiente",
    // storageBucket: "apoyo-eficiente.appspot.com",
    // messagingSenderId: "578591540163",
    // appId: "1:578591540163:web:1daaf5fe9c9ddb6e7cb376",
    // measurementId: "G-330N44Q97F",


    // Dev
    apiKey: "AIzaSyAWsicsWWVDjf8VCa-hNwpUyDUsCORQiic",
  authDomain: "aquiestoy-cf54a.firebaseapp.com",
  projectId: "aquiestoy-cf54a",
  storageBucket: "aquiestoy-cf54a.appspot.com",
  messagingSenderId: "280024856395",
  appId: "1:280024856395:web:519bbd63ada64c75471ace"
  
    // SLP
    // apiKey: "AIzaSyCz4WZwdhZwODObHEtd9-pxBlKh5s7azSo",
    // authDomain: "aeslp-8b081.firebaseapp.com",
    // projectId: "aeslp-8b081",
    // storageBucket: "aeslp-8b081.appspot.com",
    // messagingSenderId: "629639439819",
    // appId: "1:629639439819:web:f043459d95563c3ebe824e",
    // measurementId: "G-H8DRE3N6EJ"
  }
};
