import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return true;
    //return this.validateData(next.data);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  validateData(data: any) {
    const allowedRole = data.role;
    const allowedPermissions = data.permissions;
    // console.log('before trying to read userProfile state')
    return this.authService.userProfile.pipe(
      take(1),
      map((user: any) => {
        let canByRole = allowedRole.includes(user.role);
        let canByPermission = false;
        const userPermissions = user.permissions || [];
        if (userPermissions.length > 0) {
          for (const permission of userPermissions) {
            if (allowedPermissions.includes(permission)) {
              canByPermission = true;
            }
          }
        }
        const valid = (canByRole || canByPermission);
        return valid ? true : this.router.parseUrl('/notauthorized');
      })
    )
  }
}
